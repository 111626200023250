import React, { useContext, useState } from 'react';
import useScript from '../ui/hooks/useScript';
import { createContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

export const KlaviyoTrackingContext = createContext(null);

export const KlaviyoTracking = ({ children }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const [latestAdded, setAdded] = useState(false);
  const apiKey =
    selectedChannel.id === 1
      ? 'RRtZgs'
      : selectedChannel.id === 2
      ? 'U3SWwZ'
      : selectedChannel.id === 3
      ? 'TcaCWU'
      : 'TEST';
  const status = useScript(
    `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`
  );
  return (
    <KlaviyoTrackingContext.Provider value={{ status, setAdded, latestAdded }}>
      {children}
    </KlaviyoTrackingContext.Provider>
  );
};

export const KlaviyoViewedProduct = ({ product }) => {
  const { status } = useContext(KlaviyoTrackingContext);
  const { selectedChannel } = useContext(ChannelContext);
  React.useEffect(() => {
    if (typeof window !== 'undefined' && status === 'ready') {
      if (!window._learnq) window._learnq = [];
      const productCategories = product?.categories?.map(
        (category) => category.name
      );
      const item = {
        ProductName: `${product?.name} ${product?.subName}`,
        ProductID: `${product?.id}`,
        SKU: `${product?.articleNumber}`,
        Categories: productCategories,
        ImageURL: `${product?.images[0]?.url}`,
        URL: `${selectedChannel.url}${product?.primaryRoute?.path}`,
        Brand: product?.categories?.filter(
          (category) =>
            category?.primaryRoute?.path?.search('/vare-merker/') !== -1
        )[0]?.name,
        Price: product.price?.incVat,
      };

      window._learnq.push(['track', 'Viewed Product', item]);

      window._learnq.push([
        'trackViewedItem',
        {
          Title: item.ProductName,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
          },
        },
      ]);
    }
  }, [product, status, selectedChannel]);
  return null;
};

export function klaviyoAddToCart(product, data) {
  if (!product) return null;

  const items = data?.cart?.items || [];
  const checkoutUrl = data?.cart?.externalCheckoutUrl;

  if (typeof window !== 'undefined') {
    if (!window._learnq) window._learnq = [];
    window._learnq.push([
      'track',
      'Added to Cart',
      {
        $value: data?.cart?.productTotal?.incVat,
        AddedItemProductName: product.product.name,
        AddedItemProductID: product.product.id,
        AddedItemSKU: product.product.articleNumber,
        AddedItemCategories: product.product.categories?.map((cat) => cat.name),
        AddedItemImageURL: product.product.images[0]?.url,
        AddedItemURL: product.product.primaryRoute.path,
        AddedItemPrice: product.price.price.incVat,
        AddedItemQuantity: product.quantity,
        ItemNames: [items.map((item) => item.product.name)],
        CheckoutURL: checkoutUrl,
        Items:
          items.length > 0
            ? items.map((item) => {
                return {
                  ProductID: item.product.id,
                  SKU: item.product.articleNumber,
                  ProductName: item.product.name,
                  Quantity: item.quantity,
                  ItemPrice: item.previousUnitPrice,
                  RowTotal: item.previousTotal,
                  ProductURL: item.product.primaryRoute.path,
                  ImageURL: item.product.images[0]?.url,
                  ProductCategories: [],
                };
              })
            : [
                {
                  ProductID: product.product.id,
                  SKU: product.product.articleNumber,
                  ProductName: product.product.name,
                  Quantity: product.quantity,
                  ItemPrice: product.price.price.incVat,
                  RowTotal: product.price.price.incVat,
                  ProductURL: product.product.primaryRoute.path,
                  ImageURL: product.product.images[0]?.url,
                  ProductCategories: [],
                },
              ],
      },
    ]);
  }
}

export function klaviyoGoToCheckout({ cart }) {
  const items = cart?.items || [];
  if (typeof window !== 'undefined') {
    if (!window._learnq) window._learnq = [];
    window._learnq.push([
      'track',
      'Started Checkout',
      {
        $event_id: Date.now(),
        $value: cart?.productTotal?.incVat,
        ItemNames: [items.map((item) => item.product.name)],
        CheckoutURL: cart?.externalCheckoutUrl,
        Items: items.map((item) => {
          return {
            ProductID: item.product.id,
            SKU: item.product.articleNumber,
            ProductName: item.product.name,
            Quantity: item.quantity,
            ItemPrice: item.previousUnitPrice,
            RowTotal: item.previousTotal,
            ProductURL: item.product.primaryRoute.path,
            ImageURL: item.product.images[0]?.url,
            ProductCategories: [],
          };
        }),
      },
    ]);
  }
}
